
$(document).ready(function () {
    let modalParentElementInDOM = $('div#main-container');
    let modalTitle = '';
    let modalMessage = '';
    let modalButtonText = 'Lukk';
    let modalAlertType = '';

    let ajaxPath = '/includes/shop/ajax/ajax.php';
    let ajaxLocked = false;
    const ajaxPathCheckCheckoutIfDeliveryTimeMatchesProductionTime = '/action/shopping-cart/check-checkout-if-delivery-time-matches-production-time';
    const ajaxPathGetRestaurantTables = '/action/checkout/get-restaurant-tables';
    let ajaxPathGetShoppingCartIconHtmlForNavbarMenu = '/action/shopping-cart/get-shopping-cart-icon-for-navbar-menu';
    let ajaxPathSetTipInShoppingCart = '/action/shopping-cart/set-tip';
    let ajaxPathGetPickUpDays = '/action/frontline-store/pick-up-days';
    let ajaxPathGetPickUpTimes = '/action/frontline-store/pick-up-times';
    let ajaxPathGetDeliveryDays = '/action/frontline-store/delivery-days';
    let ajaxPathGetDeliveryTimes = '/action/frontline-store/delivery-times';
    let shoppingCartHeader = $('header div#shopping_cart_header');

    let shopCheckoutFromWrapper = $('div#wrap div.shop-checkout-form-wrapper');
    if (shoppingCartHeader.length && shopCheckoutFromWrapper.length) {
        printShoppingCartIconInNavbarHeader();
    }

    // If delivery form is loaded -> Print available delivery times
    printTimeSelectorIfDeliveryFormIsLoaded();

    window.onSubmitRegisterUserForm = function (token) {
        $("#registerUserForm").submit();
    }

    // Activate support for international mobile numbers
    if ($('.international_mobile_number').length) {
        let mobileInputFields = document.querySelectorAll('.international_mobile_number');
        for (let i = 0, len = mobileInputFields.length; i < len; i++) {
            const iti = window.intlTelInput(mobileInputFields[i], {
                initialCountry: "no",
                formatOnDisplay: false,
                separateDialCode: true,
                hiddenInput: "full_international_number",
                utilsScript: "/htdocs/js/intlTelInputUtils.js",
                autoPlaceholder: false,
            });

            // Function to update the hidden field with the full international number
            const updateFullNumber = () => {
                const fullNumber = iti.getNumber();
                const hiddenInput = document.querySelector('input[name="full_international_number"]');
                if (hiddenInput) {
                    hiddenInput.value = fullNumber;
                }
            };

            // Listen for changes in the country code
            mobileInputFields[i].addEventListener('countrychange', updateFullNumber);

            // Listen for input changes in the phone number
            mobileInputFields[i].addEventListener('input', updateFullNumber);
        }

        // Only allow numbers as phone number input
        $(document).on('keyup', '.international_mobile_number', function () {
            let phoneNumberOnlyNumbers = $(this).val().replace(/\D/g,'');
            $(this).val(phoneNumberOnlyNumbers);
        });



        // Disable form submit and display error message if invalid mobile number
        $("form#registerUserForm").submit(function(e){
            var input = document.querySelector('form#registerUserForm #phone');
            var iti = window.intlTelInputGlobals.getInstance(input);
            iti.isValidNumber(); // etc

            if (!iti.isValidNumber()) {
                e.preventDefault(e);

                modalTitle = 'Ugyldig mobilnummer!';
                modalMessage = 'Ugyldig mobilnummer / Invalid mobile number';
                modalAlertType = 'error';

                displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);

                return false;
            }
        });
    }

    // Disable input in tip modal when modal is hidden
    $(document).on('hide.bs.modal','div#custom_tip_modal', function () {
        let customTipAmountInput = $('div#custom_tip_modal input#custom_tip_amount');
        if (customTipAmountInput.length) {
           customTipAmountInput.prop('disabled', true);
        }
    });

    // Enable input in tip modal when modal is opened
    $(document).on('show.bs.modal','div#custom_tip_modal', function () {
        let customTipAmountInput = $('div#custom_tip_modal input#custom_tip_amount');
        if (customTipAmountInput.length) {
            customTipAmountInput.prop('disabled', false);
        }
    });


    $(document).on('click', 'div.shop-checkout-form-wrapper button#add_discount_code', function () {
        if ($('div.shop-checkout-form-wrapper input[name="discount_code"]').val() !== '') {
            removeCheckoutConfirmOrder();
        }
    });


    $(document).on('change', 'div.timepoint_row .timebox', function () {
        //let selectedDay = $('div.timepoint_row select[name^="time_point_arr"]').children("option:selected").val();
        let timeRow = $(this).closest('div.timepoint_row');
        let selectedDay = $('select:eq(0)', timeRow).val();
        let selectedTime = $('select:eq(1)', timeRow).val();
        validateSelectedDeliveryTimeAgainstProductsInShoppingCart(selectedDay, selectedTime);
    });

    // Switch between delivery and pickup
    $('div.shop-checkout-form-wrapper .btn-group button').click(function () {
        // If the button is already active, do nothing
        if ($(this).hasClass('active')) {
            return;
        }

        if ($(this).attr('target') === 'shop_delivery_form_wrapper') {
            // Display delivery form
            $('div#shop_delivery_form_wrapper').show();
            $('div#shop_pick_up_form_wrapper').hide();

            // Switch active class
            // First remove active
            $('div.shop-checkout-form-wrapper .btn-group button').each(function () {
                $(this).removeClass('active');
            });
            $(this).addClass('active');
        } else if ($(this).attr('target') === 'shop_pick_up_form_wrapper') {
            // Display pick up form
            $('div#shop_delivery_form_wrapper').hide();
            $('div#shop_pick_up_form_wrapper').show();

            // Switch active class
            // First remove active
            $('div.shop-checkout-form-wrapper .btn-group button').each(function () {
                $(this).removeClass('active');
            });
            $(this).addClass('active');
        }


    });

    //Selects the contact person in the other textbox based on the id written on #employee_id text box
    $('#employee_id').on('input', function () {
        let employeeId = $('#employee_id').val();
        $('#contact_person_picker option[employee_id="'+employeeId+'"]').prop('selected', true);
        whenContactPersonPickerChanges();
    });

    $(document).on('click', '#add_time_points', function () {
        let max = $('#add_time_points').attr('max') * 1;
        let amountOfTimeSelectorsBefore = $('.timepoint_row').length;
        if (amountOfTimeSelectorsBefore < max) {
            fetchAvailableDays(amountOfTimeSelectorsBefore,true);
        }

        let amountOfTimeSelectorsAfter = $('.timepoint_row').length;
        if (amountOfTimeSelectorsAfter <= 1) {
            $('#remove_time_points').attr('disabled', true);
        }

    });

    $(document).on('click', '#remove_time_points', function () {
        let max = $('#add_time_points').attr('max') * 1;
        let amountOfTimeSelectorsBefore = $('.timepoint_row').length;
        if (amountOfTimeSelectorsBefore < 2 ){
            return;
        }

        let lastTimePointRow = $('.timepoint_row');
        lastTimePointRow.prev('div.clearfix').last().remove();
        lastTimePointRow.last().remove();

        let amountOfTimeSelectorsAfter = $('.timepoint_row').length;
        if (amountOfTimeSelectorsAfter < max) {
            $('button#add_time_points').attr('disabled', false);
        }

        if (amountOfTimeSelectorsAfter === 1) {
            $('button#remove_time_points').attr('disabled', true);
        }
    });


    $(document).on('change', 'input[name="payment_method"]', function () {
        markSelectedPaymentMethod($(this));
    });

    $(document).on('click', "div.shop-checkout-form-wrapper div.tip-panel button:not('.optional-tip-button'), div.shop-checkout-form-wrapper div#custom_tip_modal button#add_custom_tip", function () {
        removeCheckoutConfirmOrder();
        setTip($(this));
    });


    $('input[name="payment_method"]').click(function () {
        if ($(this).val() === 'frontline_invoice')
        {
            $('div#add_new_delivery_time_form_group').show();
            $('button#add_time_points').show();
            $('button#remove_time_points').show();
            $('.timepoint_row').each(function( index ) {
                if (index !== 0)
                    $(this).show();
            });
        }
        else {
            $('div#add_new_delivery_time_form_group').hide();
            $('.timepoint_row').each(function( index ) {
                if (index !== 0) {
                    $(this).hide();
                }
            });
        }
    });

    $('select[name="contact_person_picker"]').change(function () {
        whenContactPersonPickerChanges();
    });

    // Change in select list with registered delivery addresses
    // Change values in address input fields
    function whenContactPersonPickerChanges() {
        // The new values are stored as html key value pairs in the option
        var option = $('option:selected', $('select[name="contact_person_picker"]'));

        $('input#employee_name').val(option.text());
    }

    $('.change_use_alternative_vat').click(function () {
        var sessionValue = $(this).attr('value');
        $.ajax({
            type: 'POST',
            url: ajaxPath,
            data: {
                action: 'set_session_use_alternative_vat',
                session_value: sessionValue
            }
        }).done(function (response) {
            // Validate response
            var success = true;
            if (response.status === undefined) {
                success = false;
            } else if (response.status.toUpperCase() !== 'OK') {
                success = false;
            }

            if (success) {
                //alert("Success");
                let totalPriceOnShoppingCartHtml = $('#totalPriceOnShoppingCart').html();
                if (totalPriceOnShoppingCartHtml !== undefined && totalPriceOnShoppingCartHtml.trim() !== "0,00 Kr") {
                    location.reload();
                }
            } else {
                alert("Error: " + response.error_message);
            }
        }, 'json');
    });

    // Change in select list with registered delivery addresses
    // Change values in address input fields
    $('select[name="delivery_address_picker"]').change(function () {
        // The new values are stored as html key value pairs in the option
        var option = $('option:selected', this);

        $('input#address').val(option.attr('address'));
        $('input#zip').val(option.attr('zip'));
        $('input#city').val(option.attr('city'));
        $('input#entrance').val(option.attr('entrance'));
        $('input#floor').val(option.attr('floor'));
        $('textarea#extra_adress_info').val(option.attr('extra_info'));
    });

    // Pick up day is changed
    $(document).on('change', '.pick_up_days_from_now', function () {
        let daysFromNow = $(this).val();
        let arrayId = $(this).attr('array_id');
        fetchAvailableTimes(daysFromNow, arrayId);
    });

    // Delivery day is changed
    $(document).on('change', '.delivery_days_from_now', function () {
        let daysFromNow = $(this).val();
        let arrayId = $(this).attr('array_id');
        fetchAvailableTimes(daysFromNow, arrayId, true)
    });

    // Fetch available days and times when selecting a store
    // Also fetch restaurant tables
    $(document).on('change', 'form#checkout_pick_up_form input[name=frontline_store_id]:radio',function () {
        //Testing
        fetchAvailableDays(0);
        getRestaurantTableSelector();
        $('#remove_time_points').attr('disabled', true);
    });

    // Fetch available times when selecting a day
    $(document).on('change', 'form#checkout_pick_up_form select.pick_up_time',function () {
        let daysFromNow = $(this).val();
        fetchAvailableTimes(daysFromNow, 0);
    });

    function getRestaurantTableSelector() {
        if($('div#restaurant_table_panel').length) {

            if(ajaxLocked) {
                return;
            }
            let frontlineStoreId = $('input[name=frontline_store_id]:checked:first').val();

            ajaxLocked = true;

            $.ajax({
                type: 'GET',
                url: ajaxPathGetRestaurantTables,
                data: {
                    frontline_store_id: frontlineStoreId
                },
                dataType: 'json'
            }).success(function (data) {
                $('div#restaurant_table_form_group').html(data.html);

                checkRestaurantTableName();

                if ($('div#frontline_store_id_radio').attr('posted_frontline_store_id') === frontlineStoreId) {

                    $("div#restaurant_table_panel select#select_restaurant_table").val($('div#restaurant_table_panel').attr('posted_restaurant_table')).change();
                }

            }).error(function (data) {
                modalTitle = 'En feil oppstod';
                modalMessage = $.parseJSON(data.responseText).errors;
                modalAlertType = 'error';

                displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
            }).always(function () {
                ajaxLocked = false;
            });
        }
    }

    function fetchAvailableTimes(daysFromNow = 0, arrayId = -1, delivery = false) {
        //ArrayId is set to 0 when frontlineStore is changed. Else use arrayId as the length of timepoint_row (added/subtracted when clicking on +/- buttons)
        if (arrayId === -1) {
            arrayId = $('.timepoint_row').length;//$('[name*="time_point_arr"]').length/2;
        }

        let frontlineStoreId;
        let ajaxPathUrl;
        if (delivery) {
            ajaxPathUrl = ajaxPathGetDeliveryTimes;
            frontlineStoreId = $('input[name=frontline_store_id]').val();
        } else {
            ajaxPathUrl = ajaxPathGetPickUpTimes;
            frontlineStoreId = $('input[name=frontline_store_id]:checked:first').val();
        }

        $.ajax({
            type: 'GET',
            url: ajaxPathUrl,
            data: {
                frontline_store_id: frontlineStoreId,
                array_id: arrayId,
                days_from_now: daysFromNow
            },
            dataType: 'json'
        }).success(function (data) {
            appendTimeSelectorToRow(data.html_time_selector, arrayId, delivery);
            printLocationClosedIfPanelsWithOpeningHoursAreHidden();
        }).error(function (data) {
            modalTitle = 'En feil oppstod';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        });
    }

    function fetchAvailableDays(arrayId = -1, appendRow = false, delivery = false) {
        let formGroup = $('div#shop_pick_up_form_wrapper div.pickup-food-panel div#delivery_time_form_group');

        //ArrayId is set to 0 when frontlineStore is changed. Else use arrayId as the length of timepoint_row (added/subtracted when clicking on +/- buttons)
        if (arrayId === -1) {
            arrayId = $('.timepoint_row').length;
        }

        let frontlineStoreId;
        let ajaxPathUrl;
        if (delivery) {
            ajaxPathUrl = ajaxPathGetDeliveryDays;
            frontlineStoreId = $('input[name=frontline_store_id]').val();
        }
        // Pick up
        else {
            ajaxPathUrl = ajaxPathGetPickUpDays;
            frontlineStoreId = $('input[name=frontline_store_id]:checked:first').val();
        }

        $.ajax({
            type: 'GET',
            url: ajaxPathUrl,
            data: {
                frontline_store_id: frontlineStoreId,
                array_id: arrayId,
            },
            dataType: 'json'
        }).success(function (data) {
            if (appendRow) {
                formGroup.append(data.html_day_selector);

                //Disable PLUSS button if maximum rows of time selectors is reached
                if ($('div#add_new_delivery_time_form_group').length) {
                    disablePlussButtonIfMaxRowCountIsReached();
                }
            } else {
                formGroup.html(data.html_day_selector);
            }

            let daySelector = formGroup.find('div#rowId' + arrayId + ' div.day-selector-wrapper select');
            let selectedDaysFromNow = daySelector.find('option:selected').val();
            fetchAvailableTimes(selectedDaysFromNow, arrayId, delivery);
            activateDatepicker();

        }).error(function (data) {
            modalTitle = 'En feil oppstod';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        });
    }

    function appendTimeSelectorToRow(html, arrayId, delivery) {
        let wrapperId;
        if (delivery) {
            wrapperId = 'shop_delivery_form_wrapper';
        } else {
            wrapperId = 'shop_pick_up_form_wrapper';
        }

        let row = $('div#' + wrapperId + ' div.pickup-food-panel div#delivery_time_form_group div#rowId' + arrayId);
        let timeSelectorWrapper = row.find('div.time-selector-wrapper');

        if (timeSelectorWrapper.length) {
            timeSelectorWrapper.remove();
        }

        row.append(html);
    }

    if ($('form#checkout_pick_up_form').is(':visible')) {
        if ($("input[name='frontline_store_id']:checked").val()) {
            $("input[name='frontline_store_id']:checked").change();
        }
    }

    function disablePlussButtonIfMaxRowCountIsReached() {
        let max = $('#add_time_points').attr('max') * 1;
        let amountOfTimeSelectors = $('.timepoint_row').length;

        if (amountOfTimeSelectors === max) {
            $('button#add_time_points').attr('disabled', true);
        }

        if(amountOfTimeSelectors > 1) {
            $('#remove_time_points').attr('disabled', false);
        }
    }

    // Prevent double submission
    //$('form#checkout_delivery_form').preventDoubleSubmission();
    //$('form#checkout_pick_up_form').preventDoubleSubmission();

    $('button#register-button').click(function (e) {
        if (ajaxLocked === true) {
            return;
        }

        ajaxLocked = true;

        if ($('#contact_person_picker').length > 0) {
            if ($('#contact_person_picker').val() == null || $('#contact_person_picker').val() == '') {
                alert('Du må velge kontaktperson');
                ajaxLocked = false;
                return;
            }
        }

        if ($('#tosCheckbox').length > 0) {
            if (!$('#tosCheckbox').is(':checked')) {
                alert('Du må akseptere våre kjøpsvilkår for å bestille');
                ajaxLocked = false;
                return;
            }
        }

        let noteTextarea = $('textarea[name="note"]');
        if(noteTextarea.prop('required') && $.trim(noteTextarea.val()) === ""){
            return;
        }

        if(!validateCheckoutForm()) {
            //If panel is hidden -> Customer setting is activated and user can therefore not see if it is possible to order at this time
            if ($('form#checkout_pick_up_form div.pickup-food-panel').is(":hidden")) {
                alert('Vi tar dessverre ikke imot bestillinger akkurat nå fordi vi har stengt, men du er velkommen tilbake senere :) ')
            } else {
                alert('Det er dessverre ikke mulig å bestille til valgt tidspunkt.');
            }
            ajaxLocked = false;
            return;
        }

        $('img#checkout_submit_loadig').removeClass('hidden');
        $(this).addClass('hidden');
    });

    if ($('input[name="payment_method"]').val() === 'frontline_invoice') {
        $('div#add_new_delivery_time_form_group').show();
        $('#add_time_points').show();
        $('#remove_time_points').show();
    }
    else {
        $('div#add_new_delivery_time_form_group').hide();
    }

    $(document).on('submit', 'form#checkout_delivery_form', function(){
        return validateCheckoutForm();
    });
    $(document).on('submit', 'form#checkout_pick_up_form', function(){
        return validateCheckoutForm();
    });

    function printLocationClosedIfPanelsWithOpeningHoursAreHidden() {
        if ($('div#shop_pick_up_form_wrapper').is(":visible") && $('div#shop_pick_up_form_wrapper div.pickup-food-panel').is(":hidden")) {
            if (!validateCheckoutForm()) {
                let title = 'Serveringsstedet er stengt';
                let message = 'Vi tar dessverre ikke imot bestillinger akkurat nå, men du er velkommen tilbake senere.';
                let type = 'danger';
                $(getAlertHtml(message, type, title)).insertBefore($('div#shop_pick_up_form_wrapper'));

                let placeOrderButton = $('button#register-button');
                placeOrderButton.prop('disabled',true);
                placeOrderButton.css('cursor', 'not-allowed');
                placeOrderButton.css('pointer-events', 'auto');

                let submitButtonFormGroup = $('button#register-button').closest('div.form-group');
                $('<div class="alert alert-danger">' + title + '</div>').insertBefore(submitButtonFormGroup);
            }
        }
    }

    function checkRestaurantTableName() {
        if (!$('div#restaurant_table_form_group span#restaurant_table_name').length) {
            return;
        }

        let textElement = $('div#restaurant_table_form_group span#restaurant_table_name');
        let textLength = textElement.html().trim().length

        // If amount of characters is more than 4 -> make a square around the text instead for circle
        if (textElement.length && textLength > 4) {

            if (window.matchMedia("only screen and (max-width: 768px)").matches) {
                textElement.wrap("<div id='square_around_table_name_mobile'></div>");
            } else {
                textElement.wrap("<div id='square_around_table_name'></div>");
            }

            textElement.removeClass('circle-around-text');

            if (textLength > 60) {
                $('div#square_around_table_name').css("align-items", "normal");
            }
        }
    }

    function printShoppingCartIconInNavbarHeader() {
        $.ajax({
            type: 'GET',
            url: ajaxPathGetShoppingCartIconHtmlForNavbarMenu,
            data: {},
            dataType: 'json'
        }).success(function (data) {
            if (data.html !== '') {
                let shoppingCartIcon = $('header div#shopping_cart_header');
                shoppingCartIcon.replaceWith(data.html);
            }
        }).error(function (data) {
            modalTitle = 'En feil oppstod';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        }).always(function () {
        });
    }

    function setTip(buttonClickedElement) {
        let formData = new FormData;
        if (buttonClickedElement.attr('id') === 'add_custom_tip') {
            let customTipAmount = $('div#custom_tip_modal input#custom_tip_amount').val();
            formData.append('tip_amount', parseFloat(customTipAmount.replace(',','.').replace(' ','')));
        } else {
            formData.append('tip_percent', buttonClickedElement.val());
        }

        $.ajax({
            type: 'POST',
            url: ajaxPathSetTipInShoppingCart,
            data: formData,
            processData: false,
            contentType: false,
            dataType: 'json'
        }).success(function (data) {
            location.reload();
        }).error(function (data) {
            modalTitle = 'Det oppstod en feil!';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        });
    }

    function removeCheckoutConfirmOrder() {
        // Remove the TOS required attribute, so that we can submit the form.
        $('div.shop-checkout-form-wrapper input#tosCheckbox').removeAttr('required');

        // Remove the input field that confirms the order
        let inputName = '';
        if ($('form#checkout_pick_up_form').length) {
            inputName = 'confirm_pick_up';
        } else {
            inputName = 'confirm_delivery';
        }

        $('div.shop-checkout-form-wrapper input[name="' + inputName + '"]').remove();
    }

    function validateCheckoutForm(){
        var validation = true;

        $('.timepoint_row select.timebox').each(function(){
            if(!($('option', this).length > 0) || this.value == ''){
                validation = false;
            } else {
            }
        });

        return validation;
    }

    function activateDatepicker() {
        if ($('input.datepicker-input').length > 0 && jQuery().pickadate) {
            var max = undefined;
            if ($('input.datepicker-input:first').attr('max_days') > -1) {
                var maxDays = $('input.datepicker-input:first').attr('max_days');
                var maxDate = new Date();
                maxDate = new Date(maxDate.getTime() + maxDays * 86400000);
                max = maxDate;
            }
            $('.datepicker-input').pickadate({
                format: 'dd.mm.yyyy',
                formatSubmit: 'dd.mm.yyyy',
                close: 'Lukk',
                container: 'body',
                selectYears: 5,
                selectMonths: true,
                min: new Date(),
                max: max,
                onSet: function (context) {
                    var parentRow = $('input#' + this.get('id')).closest('.row');
                    var selectElement = $('select#order_day_select', parentRow);
                    var dateNow = new Date();
                    dateNow.setHours(0);
                    dateNow.setMinutes(0);
                    dateNow.setSeconds(0);
                    dateNow.setMilliseconds(0);
                    var timeNow = dateNow.getTime();
                    var days = Math.round((context.select - timeNow) / (86400000));
                    if(!isNaN(days)) {
                        selectElement.html('<option value="' + days + '">' + days + '</option>');
                        $('option:first', selectElement).attr('selected', true);
                        selectElement.change();
                    }
                }
            });
        }
    }
    activateDatepicker();

    function validateSelectedDeliveryTimeAgainstProductsInShoppingCart(selectedDay, selectedTime) {
        if(ajaxLocked) {
            return;
        }
        let deliveryMethod = '';
        let frontlineStoreId = $('input[name=frontline_store_id]:checked:first').val();
        let validation = false;
        let shoppingCartSmall = $('div#shopping_cart_small div.shopping_cart_product_container');
        let products = [];

        if(shoppingCartSmall.length) {
            products = getProductsFromShoppingCart(shoppingCartSmall)
        }
        if($('div.shop-checkout-form-wrapper form#checkout_delivery_form').length) {
            deliveryMethod = 1
        }
        if($('div.shop-checkout-form-wrapper form#checkout_pick_up_form').length) {
            deliveryMethod = 2;
        }

        ajaxLocked = true;

        $.ajax({
            type: 'GET',
            url: ajaxPathCheckCheckoutIfDeliveryTimeMatchesProductionTime,
            data: {
                frontline_store_id: frontlineStoreId,
                selected_day: selectedDay,
                selected_time: selectedTime,
                products: products,
                delivery_method: deliveryMethod
            },
            dataType: 'json'
        }).success(function (data) {
            validation = true;

        }).error(function (data) {
            modalTitle = 'Vær oppmerksom';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'info';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        }).always(function () {
            ajaxLocked = false;
        });
        return validation;
    }

    function markSelectedPaymentMethod(clickedElement) {
        let recentSelected = $('div.payment-panel').find('.selected-payment-method');
        recentSelected.removeClass('selected-payment-method');
        let paymentMethodContainer = clickedElement.closest('.payment-method-container');
        paymentMethodContainer.addClass('selected-payment-method');
    }

    function printTimeSelectorIfDeliveryFormIsLoaded() {
        let deliveryForm = $('form#checkout_delivery_form');
        if (deliveryForm.length) {
            let formGroup = $('div#shop_delivery_form_wrapper div.pickup-food-panel div#delivery_time_form_group');
            let daySelector = formGroup.find('div#rowId0 div.day-selector-wrapper select');
            let arrayId = $(daySelector).attr('array_id');
            let selectedDaysFromNow = daySelector.find('option:selected').val();

            fetchAvailableTimes(selectedDaysFromNow, arrayId, true);
        }
    }

});


